import IS from '../translations/is.json'
import EN from '../translations/en.json'

export default function t(text, language) {
  let result = ''
  if (language === 'is') {
    result = IS[text]
  } else if (language === 'en') {
    result = EN[text]
  }
  if (result === undefined) return text
  return result
}