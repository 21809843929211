import React, { useEffect, useMemo, useState } from 'react'
import Container from '@mui/material/Container';
import Navigation from '../components/Navigation';
import { Box, Chip, Divider, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundedButton } from '../components/RoundedButton';
import { ArrowBack, ArrowForward, FlightLand, FlightTakeoff, KeyboardArrowDown, Search } from '@mui/icons-material';
import { theme } from '../styles/theme/theme';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import t from '../utils/translation'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { times } from '../utils/time';
import debounce from 'lodash.debounce';
import { Helmet } from 'react-helmet-async';

function FIDS({ changeLocale }) {
  const [currentTab, setCurrentTab] = useState('departures');
  const [destination, setDestination] = useState('All destinations');
  const [destinations, setDestinations] = useState([])
  const [day, setDay] = useState(dayjs());
  const [time, setTime] = useState(dayjs().format('HH:00'));
  const [arrivals, setArrivals] = useState([]);
  const [filteredArrivals, setFilteredArrivals] = useState([]);
  const [departures, setDepartures] = useState([]);
  const [filteredDepartures, setFilteredDepartures] = useState([]);
  const [language] = useState(window.location.href.includes('/flugupplysingar') ? 'is' : 'en')
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [lastFetched, setLastFetched] = useState(new Date());
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const minDate = dayjs().subtract(2, 'month').set('hour', 0).set('minute', 0);
  const maxDate = dayjs().add(7, 'day').set('hour', 0).set('minute', 0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    fetchFlights(true)
  }, [language, day])

  useEffect(() => {
    setFilteredArrivals(filterFlights(arrivals));
    setFilteredDepartures(filterFlights(departures));
  }, [departures, arrivals, destination, time, search])

  useEffect(() => {
    updateDestinations()
  }, [departures, arrivals])
  
  useEffect(() => {
    setLoading(false)
  }, [filteredArrivals, filteredDepartures])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchFlights()
    }, 60000);

    return () => clearInterval(interval);
  }, [])

  const fetchFlights = (showLoading=false) => {
    if (showLoading) {
      setLoading(true)
    }
    let windowSearch = window.location.search;
    let params = new URLSearchParams(windowSearch)

    let queryUrl = 'https://flightinfo-api.isavia.is/flight'
    queryUrl += '?dateFrom=' + day.format('YYYY-MM-DD') + '%2000%3A00'
    queryUrl += '&dateTo=' + day.format('YYYY-MM-DD') + '%2023%3A59'
    queryUrl += '&language=' + language

    if (params.get('gates') != null || params.get('hlid') != null) {
      queryUrl += '&showAllGates=true'
    }

    fetch(queryUrl + '&departures=false')
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.Items) {
        setArrivals(data.Items)
      }
    });
    
    fetch(queryUrl + '&departures=true')
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.Items) {
        setDepartures(data.Items)
      }
    });
    setLastFetched(new Date())
  }

  const filterFlights = (data) => {
    // Search filter
    if (search.length !== 0) {
      data = data.filter((flight) => {
        if (
            flight.Airline.toLowerCase().includes(search.toLowerCase()) ||
            flight.No.toLowerCase().includes(search.toLowerCase()) ||
            flight.DisplayName.toLowerCase().includes(search.toLowerCase()) 
        ) {
          return true
        }
        return false
      })
    }

    // Destination filter
    if (destination !== 'All destinations') {
      data = data.filter((flight) => {
        if (flight.DisplayName.toLowerCase().includes(destination.toLowerCase())) {
          return true
        }
        return false
      })
    }

    // Date filter
    data = data.filter((flight) => {
      if (dayjs(flight.Scheduled).format('DD-MM-YYYY') === day.format('DD-MM-YYYY')) {
        return true
      }
      return false
    })

    // Time filter
    data = data.filter((flight) => {
      if (dayjs(flight.Scheduled).format('HH:mm') >= dayjs(time, 'HH:mm').format('HH:mm')) {
        return true
      }
      return false
    })
    
    return data
  }

  const updateDestinations = () => {
    let destArray = []
    arrivals.forEach(flight => {
      if (!destArray.includes(flight.DisplayName)) {
        destArray.push(flight.DisplayName)
      }
    });
    departures.forEach(flight => {
      if (!destArray.includes(flight.DisplayName)) {
        destArray.push(flight.DisplayName)
      }
    });
    
    if (!destArray.includes(destination)) {
      setDestination('All destinations')
    }

    destArray.sort()
    setDestinations(destArray)
  }

  const getStatusDisplay = (status, arrival) => {
    if (arrival) {
      // Arrivals
      if (status.indexOf('On time') !== -1 || status.indexOf('Á áætlun') !== -1) {
        return <Typography variant='body2' color='primary'>{status}</Typography>
      }
      if (status.indexOf('Estimated') !== -1 || status.indexOf('Áætluð koma') !== -1) {
        return <Typography variant='body2' color='primary'>{status}</Typography>
      }
      if (status.indexOf('Landed') !== -1 || status.indexOf('Lent') !== -1) {
        return <Chip label={status} color='primary' sx={{ minWidth: 100 }} />
      }
      if (status.indexOf('Cancelled') !== -1 || status.indexOf('Aflýst') !== -1) {
        return <Chip label={status} color='secondary' sx={{ minWidth: 100 }} />
      } 
    } else {
      // Departures
      if (status.indexOf('On time') !== -1 || status.indexOf('Á áætlun') !== -1) {
        return <Typography variant='body2' color='primary'>{status}</Typography>
      }
      if (status.indexOf('Estimated') !== -1 || status.indexOf('Áætluð brottför') !== -1) {
        return <Typography variant='body2' color='primary'>{status}</Typography>
      }
      if (status.indexOf('Go to gate') !== -1 || status.indexOf('Fara að hliði') !== -1) {
        return <Chip label={status} color='primary' sx={{ minWidth: 100 }} />
      }
      if (status.indexOf('Boarding') !== -1 || status.indexOf('Hlið opið') !== -1) {
        return <Chip label={status} color='primary' sx={{ minWidth: 100 }} />
      }
      if (status.indexOf('Final Call') !== -1 || status.indexOf('Lokaútkall') !== -1) {
        return <Chip label={status} color='primary' sx={{ minWidth: 100 }} />
      }
      if (status.indexOf('Gate Closed') !== -1 || status.indexOf('Hliði lokað') !== -1) {
        return <Chip label={status} color='warning' sx={{ minWidth: 100 }} />
      }
      if (status.indexOf('Departed') !== -1 || status.indexOf('Farin') !== -1) {
        return <Typography variant='body2' color='text.secondary'>{status}</Typography>
      }
      if (status.indexOf('Delayed') !== -1 || status.indexOf('Seinkað') !== -1) {
        return <Chip label={status} color='secondary' sx={{ minWidth: 100 }} />
      }
      if (status.indexOf('Cancelled') !== -1 || status.indexOf('Aflýst') !== -1) {
        return <Chip label={status} color='secondary' sx={{ minWidth: 100 }} />
      } 
    }
    
    return <Typography variant='body2'>{status}</Typography>
  }

  const handleEarlierFlights = () => {
    //setDay(day.subtract(1, 'day'))
    let newDate = dayjs(time, 'HH:mm').subtract(2, 'hour')
    if (newDate.day() !== dayjs(time, 'HH:mm').day()) {
      if (day.subtract(1, 'day').isBefore(minDate)) {
        return
      }
      setDay(day.subtract(1, 'day'))
    }
    setTime(newDate.format('HH:mm'))
  }

  const handleLaterFlights = () => {
    let newDate = dayjs(time, 'HH:mm').add(2, 'hour')
    if (newDate.day() !== dayjs(time, 'HH:mm').day()) {
      if (day.add(1, 'day').isAfter(maxDate)) {
        return
      }
      setDay(day.add(1, 'day'))
    }
    setTime(newDate.format('HH:mm'))
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const debouncedHandleSearchChange = useMemo(() => debounce(handleSearchChange, 500), [])

  return (
  <>
  <Helmet>
<title>{ language === "is" ? "Flugupplýsingar | Keflavíkurflugvöllur" : "Flight info | Keflavik Airport"}</title>
<meta name='description' content={ language === "is" ? "Vefur Keflavíkurflugvallar - allt sem viðkemur flugi til og frá Keflavík. Komur og brottfarir, bókun á bílastæði, verslun og veitingarstaðir, samgöngur og margt fleira." : "Official Keflavik Airport Website - information about flights to and from Keflavik Iceland.  Arrivals and departures, parking, transportation, shops and restaurants."} />
</Helmet>
    <Navigation language={language}/>
    <Container disableGutters  sx={{ pt: { xs: 0, sm: 9 } }}>
      {/* SEARCH */}
      <Box sx={{  display: { xs: 'flex', sm: 'none'}, justifyContent: 'center', alignItems: 'center', mt: 4, mb: 2, mx: 3 }}>
        <TextField 
          size='small' 
          fullWidth 
          onChange={debouncedHandleSearchChange}
          placeholder={t('Search Placeholder', language)} 
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search color='primary' sx={{ width: 24, height: 24 }}  />
              </InputAdornment>
            ),
            sx: ({
              py: .5,
              backgroundColor: '#ffffff',
              border: '1px solid #E8E8E8'
            })
          }}
        />
      </Box>
      {/* TABS */}
      <Box sx={{ borderBottom: 1, borderColor: '#e7e7e7' }}>
        <Tabs 
          value={currentTab} 
          onChange={(e, tab) => setCurrentTab(tab)} 
          centered 
          variant={ useMediaQuery(theme.breakpoints.down('sm')) ? 'fullWidth' : 'standard' }
          sx={{ height: 60 }}
          
        >
          <Tab 
            label={t('Arrivals', language)} 
            value='arrivals' 
            icon={<FlightLand sx={{ height: 24, width: 24 }} />} 
            iconPosition='start'
            sx={{ fontSize: {xs: 20, sm: 32}, textTransform: 'none', color: 'text.primary', '&.Mui-selected': { color: 'primary' } }} 
            disableRipple
          />
          <Tab 
            label={t('Departures', language)} 
            value='departures' 
            icon={<FlightTakeoff sx={{ height: 24, width: 24 }} />} 
            iconPosition='start' 
            sx={{ fontSize: {xs: 20, sm: 32}, textTransform: 'none', ml: { xs: 0, sm: 9 }, color: 'text.primary', '&.Mui-selected': { color: 'primary' } }} 
            disableRipple
          />
        </Tabs>
      </Box>
      {/* Filter & Buttons */}
      <Grid container justifyContent='space-between' alignItems='center' sx={{ mt: { xs: 1, sm: 5 } }}>
        <Grid item sx={{ display: { xs: 'none', sm: 'block' }}}>
          <RoundedButton variant='outlined' onClick={handleEarlierFlights} startIcon={<ArrowBack />}>{t('Earlier flights', language)}</RoundedButton>
        </Grid>
        <Grid item sx={{ display: { xs: 'block', sm: 'none' }}}>
          <IconButton color='primary' sx={{ ml: 2, height: 35, width: 35, backgroundColor: 'white', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)' }} onClick={handleEarlierFlights}>
            <ArrowBack sx={{ fontSize: '20px'}} />
          </IconButton>
        </Grid>
        <Grid item container xs={'auto'} justifyContent='center'>
          {!mobile &&
            <Grid item>
              <Select 
                fullWidth 
                value={destination} 
                onChange={(e) => setDestination(e.target.value)} 
                sx={{ width: 200 }} 
                IconComponent = {(props) => <ThemedKeyboardArrowDown sx={{ fontSize: 24 }} {...props} /> }
                MenuProps={{ style: { maxHeight: 400 }}}
              >
                <MenuItem value='All destinations'>{t('All destinations', language)}</MenuItem>
                {destinations.map((dest) => (
                  <MenuItem value={dest} key={dest}>{dest}</MenuItem>
                ))}
              </Select>
            </Grid>
          }
          <Grid item sx={{ ml: 2 }}>
            <DesktopDatePicker 
              value={day}
              onChange={(newValue) => {
                setDay(newValue);
              }}
              open={datePickerOpen}
              onOpen={() => setDatePickerOpen(true)}
              onClose={() => setDatePickerOpen(false)}
              inputFormat='DD.MM.YYYY'
              minDate={dayjs().subtract(2, 'month')}
              maxDate={dayjs().add(7, 'day')}
              components={{
                OpenPickerIcon: (props) => <KeyboardArrowDown color='primary' sx={{ fontSize: { xs: 20, sm: 24 }, ...(datePickerOpen && { transform: 'rotate(180deg)' }) }} {...props} />
              }}
              renderInput={(params) => 
                <TextField 
                  sx={{ width: { xs: 110, sm: 140 } }}
                  {...params} 
                  inputProps={{ 
                    value: dayjs(params.inputProps.value).format('DD.MM.YYYY') === dayjs().format('DD.MM.YYYY') ? t('Today', language) : params.inputProps.value,
                    style: ({ cursor: 'pointer', textAlign: 'right', paddingLeft: 0 })
                  }}
                  onClick={() => setDatePickerOpen(true)}
                />
              }
            />
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Select 
              value={time}
              onChange={(e) => setTime(e.target.value)}
              sx={{ width: { xs: 70, sm: 95 } }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300
                  },
                },
              }}
              IconComponent={(props) => <ThemedKeyboardArrowDown sx={{ fontSize: { xs: 20, sm: 24 } }} {...props} />}
              inputProps={{
                sx: ({ paddingLeft: { xs: '0px', sm: '14px'}, paddingRight: { xs: '0px !important', sm: '40px !important' } })
              }}
            >
              {times.map((time, i) => {
                return <MenuItem value={time}>{time}</MenuItem>
              })}
            </Select>
          </Grid>          
        </Grid>
        <Grid item sx={{ display: { xs: 'none', sm: 'block' }}}>
          <RoundedButton variant='outlined' onClick={handleLaterFlights} endIcon={<ArrowForward />}>{t('Later flights', language)}</RoundedButton>
        </Grid>
        <Grid item sx={{ display: { xs: 'block', sm: 'none' }}}>
          <IconButton color='primary' sx={{ mr: 2, height: 35, width: 35, backgroundColor: 'white', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)' }} onClick={handleLaterFlights}>
            <ArrowForward sx={{ fontSize: '20px'}} />
          </IconButton>
        </Grid>
      </Grid>
      {/* TABLE */}
      <TableContainer component={Paper} elevation={0} sx={{ mt: { xs: 1, sm: 3 }, mb: 10, px: { xs: 2, sm: 0 }, backgroundColor: { xs: 'background.default', sm: 'white' } }}>
        <Table sx={{ borderCollapse: 'separate', borderTop: { xs:'1px solid #E7E7E7', sm: 'none'} }} >
          <TableHead sx={{ display: { xs: 'none', sm: 'table-header-group'}}}>
            <TableRow>
              <TableCell>
                <Typography variant='body1' color='text.secondary'>
                  {t('Time', language)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1' color='text.secondary'>
                  {currentTab === 'arrivals' ? t('Arriving from', language) : t('Destination', language)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1' color='text.secondary'>
                  {t('Airline', language)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1' color='text.secondary'>
                  {t('Flight number', language)}
                </Typography>
              </TableCell>
              {currentTab !== 'arrivals' &&
              <TableCell>
                <Typography variant='body1' color='text.secondary'>
                  {t('Gate info', language)}
                </Typography>
              </TableCell>
              }
              <TableCell>
                <Typography variant='body1' color='text.secondary'>
                  {t('Status', language)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && 
              <>
              {[1,2,3,4,5].map((row) => (
                <TableRow key={row}>
                  {[...(mobile ? [1,2,3,4] : [1,2,3,4,5,6])].map((col) => <TableCell key={col}><Skeleton /></TableCell>)}
                </TableRow>
              ))}
              </>
            }
            {!loading && (currentTab === 'arrivals' ? filteredArrivals : filteredDepartures).map((row) => (
              <TableRow key={row.Id}>
                <TableCell padding={ mobile ? 'none' : 'normal'}>
                  <Typography variant='body1' color='primary'>
                    {dayjs(row.Scheduled).format('HH:mm')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {row.DisplayName}
                  </Typography>
                  <Typography variant='body3' color='text.secondary' sx={{ display: { xs: 'block', sm: 'none' }}}>
                    {row.No}
                  </Typography>
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                  <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                    {row.Logo !== null && row.Logo !== "" &&
                    <img src={'https://flightinfo-api.isavia.is' + row.Logo} alt='Airline' width={30} height={24} />
                  }
                  </Box>
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }}}>
                  <Typography variant='body1' color='text.secondary'>
                    {row.No}
                  </Typography>
                </TableCell>
                  {currentTab !== 'arrivals' &&
                    <TableCell>
                      {row.Gate &&
                        <Grid container>
                          <Grid item width={20}>
                            <Typography variant='body2' color='primary'>{row.Gate}</Typography>
                          </Grid>
                          {/* {!mobile && // TODO: Check if walking time is available when it has been implemented
                          <Grid item>
                            <Typography variant='body1' color='text.secondary' sx={{ display: 'inline-flex', verticalAlign: 'middle', ml: 2 }}>
                              <DirectionsWalkIcon sx={{ height: 20 }}/>{row.walkingTime}
                            </Typography>
                          </Grid>
                          } */}
                        </Grid>
                      }
                    </TableCell>
                  }
                <TableCell colSpan={mobile && row.Gate == null ? 2 : 1} align='right' padding={mobile ? 'none' : 'normal'} width={mobile ? 160 : 240} sx={{ textAlign: { xs: 'right', sm: 'left' } }}>
                  {getStatusDisplay(row.Status, currentTab === 'arrivals' ? true : false)}
                </TableCell>
              </TableRow>
            ))}
            {(currentTab === 'arrivals' ? filteredArrivals : filteredDepartures).length < 1 && 
            <TableRow>
              <TableCell colSpan={mobile ? 4 : 6} sx={{ textAlign: 'center' }}>
                <Typography variant='body1'>{t('No flights found', language)}</Typography>
              </TableCell>
            </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {/* MOBILE TABLE - NOT BEING USED */}
      <Box sx={{ display: { xs: 'none', sm: 'none'}, mt: 3, mb: 10 }}>
        {loading && 
          <>
            {[1,2,3,4,5].map((row) => (
              <Paper elevation={0} sx={{ mb: 1, mx: 2, py: 2, px: 3 }} key={row}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}><Skeleton /></Grid>
                  <Grid item xs={6}><Skeleton /></Grid>
                  <Grid item xs={6}><Skeleton width={50} /></Grid>
                </Grid>
                <Divider sx={{ py: 1 }} />
                <Grid container columnSpacing={2} sx={{ mt: 2 }} justifyContent='space-between'>
                  <Grid item xs={6}><Skeleton width={100} /></Grid>
                  <Grid item><Skeleton width={50} /></Grid>
                </Grid>
              </Paper>
            ))}
          </>
        }
        {!loading && (currentTab === 'arrivals' ? filteredArrivals : filteredDepartures).map((row) => (
          <Paper elevation={0} sx={{ mb: 1, mx: 2, py: 2, px: 3 }} key={row.Id}>
            <Grid container justifyContent='space-between'>
              <Grid item xs={5}>
                <Typography variant='body1' fontSize={16}>
                  {row.DisplayName}
                </Typography>
                <Typography variant='body1' color='primary' fontSize={18} sx={{ mr: 1 }}>
                  {dayjs(row.Scheduled).format('HH:mm')}
                </Typography>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                  {row.Logo !== null && row.Logo !== "" &&
                    <img src={'https://flightinfo-api.isavia.is' + row.Logo} alt='Airline' width={30} height={24} />
                  }
                </Box>
                <Typography variant='body1' color='text.secondary' display='inline' fontSize={12} sx={{ ml: 1 }}>
                  {row.No}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ py: 1 }} />
            <Grid container justifyContent='space-between' flexWrap='none'>
              <Grid item sx={{ pt: 2 }}>
                {getStatusDisplay(row.Status, currentTab === 'arrivals' ? true : false)}
              </Grid>
              {currentTab !== 'arrivals' &&
                <Grid item sx={{ pt: 2 }}>
                  {row.Gate && 
                  <Grid container>
                    <Grid item>
                      <Typography variant='body1' display='inline'>Gate</Typography>
                      <Typography variant='body1' color='primary' sx={{ mr: 2, ml: 1 }} display='inline'>{row.Gate}</Typography>
                    </Grid>
                    {/* <Grid item>
                      <Typography variant='body1' color='text.secondary' sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                        <DirectionsWalkIcon sx={{ height: 20 }}/>{row.walkingTime}
                      </Typography>
                    </Grid> */}
                  </Grid>
                  }
                </Grid>
              }
            </Grid>
          </Paper>
        ))}
        {(currentTab === 'arrivals' ? filteredArrivals : filteredDepartures).length < 1 && 
          <Paper elevation={0} sx={{ mb: 1, mx: 2, py: 2, px: 3, textAlign: 'center' }}>
            <Typography variant='body1'>{t('No flights found', language)}</Typography>
          </Paper>
        }
      </Box>
    </Container>
    {lastFetched !== null &&
    <Box sx={{ position: { xs: 'relative', md: 'fixed' }, right: { xs: 'auto', md: 5 }, textAlign: { xs: 'center', md: 'right' }, bottom: 5, width: '100%' }}>
      <Typography variant='body2' color='text.secondary' sx={{ }}>{t('Updated', language)}: {dayjs(lastFetched).format("HH:mm")}</Typography>
    </Box>
    }
  </>
  )
}

const ThemedKeyboardArrowDown = (props) => {
  return <KeyboardArrowDown style={{ color: theme.palette.primary.main }} {...props} />
}

export default FIDS