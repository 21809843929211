import FIDS from "./pages/FIDS";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme/theme';
import { CssBaseline } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/is'
import { useState } from "react";

function App() {
  const helmetContext = {};
  const [locale, setLocale] = useState(window.location.pathname === '/flugupplysingar' ? 'is' : 'en')

  const changeLocale = (newLocale) => setLocale(newLocale)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FIDS changeLocale={changeLocale}/>
      </ThemeProvider>
      </HelmetProvider>
    </LocalizationProvider>
  );
}

export default App;
