import { createTheme } from '@mui/material/styles';
import ApexNew from "../../fonts/ApexNew-Book.ttf";

const customTheme = {
	palette: {
		type: 'light',
		primary: {
			main: '#E65500',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#ffffff',
		},
		background: {
			default: '#F7F7F7',
		},
		text: {
			primary: '#414042',
			secondary: '#41404280'
		},
		success: {
			main: '#d1fadf',
		},
		warning: {
			main: '#fef0c7',
		},
		error: {
			main: '#ef1400',
		},
		action: {
      disabledBackground: '#ffc0a9',
      disabled: '#ffffff'
    }
	},
	typography: {
		fontFamily: "ApexNew",
    src: `url(${ApexNew}) format("truetype")`,
		body1: {
			fontSize: '1rem', // 16px
      fontWeight: 600,
		},
		body2: {
			fontSize: '0.9rem', // 14px
			fontWeight: 600,
		},
		body3: {
			fontSize: '0.8rem', // 12px
			fontWeight: 600,
		},
		body4: {
			fontSize: '1.125rem', // 18px
		},
		fontWeightLight: 550,
		fontWeightRegular: 600,
		/* fontWeightMedium: 500, */
		fontWeightBold: 600,
		fontSize: 18,
		button: {
			fontWeight: 700,
			fontSize: 14,
		},
	},
	breakpoints: {
    values: {
      xs: 0,
      sm: 730,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
	components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "ApexNew",
          src: `url(${ApexNew}) format("truetype")`
        },
      }
    },
		MuiChip: {
			styleOverrides: {
				root: {
          borderRadius: 2,
        },
        label: {
          fontSize: '0.9rem',
					fontWeight: 550
        },
				colorSecondary: {
					backgroundColor: '#ffffff',
					color: '#E65500',
					border:  '1px solid #E65500',
				},
				colorWarning: {
					backgroundColor: '#ffffff',
					color: '#858585',
					border:  '1px solid #858585',
				}
			}
		},
    MuiSelect: {
      styleOverrides: {
        root: {
					'@media (min-width: 730px)': {
						background: '#ffffff'
					}					
        },

      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '@media (min-width: 730px)': {
						background: '#ffffff'
					}	
        }
      }
    },
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					border: 0,
					'@media (min-width: 730px)': {
						border: '1px solid #E8E8E8'
					}
				},
				input: {
					fontSize: '1rem'
				}
			}
		},
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f4f4f4'
        }
      }
    },
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: '1px solid #E7E7E7'
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontWeight: 550,
					color: '#6C6C6C'
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				edgeEnd: {
					'@media (max-width: 729px)': {
						padding: 0
					}
				}
			}
		}
	}
};

export const theme = createTheme(customTheme);