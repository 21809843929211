import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Button, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { theme } from '../styles/theme/theme'

function Navigation({language}) {
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <AppBar position='static' color='secondary' elevation={0} sx={{ py: 2 }}>

      <Toolbar>
        <Box flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <img src='https://flightinfo.isavia.is/static/kef.png' alt='logo' width={82} />
        </Box>
        <a href={language === 'is' ? 'https://www.isavia.is/flight-info' : 'https://www.isavia.is/flugupplysingar/'} style={{ textDecoration: 'none' }}>
          <Button sx={{ color: 'text.primary' }} disableRipple>
            {language === 'is' 
              ? mobile 
                ? 'EN'
                : 'English'
              : mobile
                ? 'ÍS' 
                : 'Íslenska'
            }
          </Button>
        </a>
      </Toolbar>

    </AppBar>
  )
}

export default Navigation